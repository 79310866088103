<template>
    <footer class="bg-gray-4 text-white">
        <div class="w-11/12 py-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-logo">
                <img class="w-44" src="@/views/themes/prestito/images/logo-claim-white.svg" />
            </div>
            <div class="footer-content mt-4">
                <h4 class="text-base font-semibold mb-5 sm:text-2xl">
                    Informazioni legali
                </h4>
                <p class="mb-5">
                    Messaggio pubblicitario con finalità promozionale.<br />
                    Al fine di gestire le tue spese in modo responsabile Cofidis S.A. ti
                    ricorda, prima di sottoscrivere il contratto, di prendere visione di
                    tutte le condizioni economiche e contrattuali, facendo riferimento
                    alle Informazioni Europee di Base sul Credito ai Consumatori (SECCI)
                    sul sito
                    <a href="https://www.cofidis.it" target="_blank" class="">www.cofidis.it</a>
                    sezione trasparenza. L’erogazione dei prestiti richiesti, gli importi
                    finanziabili e i massimi erogabili sono soggetti all’approvazione di
                    Cofidis S.A..
                </p>
                <p class="mb-5">
                    Il Contratto “Prestito Personale” è un finanziamento a durata determinata, caratterizzato da un
                    tasso e piano di rientro fissi, che Cofidis S.A. (di seguito “Cofidis”) mette a disposizione del
                    Consumatore concedendo un importo predeterminato contrattualmente. Il Contratto prevede generalmente
                    l’erogazione del finanziamento direttamente al Consumatore richiedente che si impegna al rimborso
                    mediante pagamento di rate mensili uguali, comprensive di capitale ed interessi.
                </p>
                <p class="mb-5">
                    Esempio rappresentativo di un prestito personale di importo pari a € 5.000 (Importo totale del credito) da
                    rimborsare in 60 mesi, con rate mensili di € 97,15, TAN fisso 6,208% - TAEG 6,50%. L’offerta del
                    prestito personale è valida fino al {{ exp_date }} ed è disponibile solo per i già clienti Cofidis in
                    possesso di <router-link @click="$emit('close')" to="promo-terms" class="underline">determinati requisiti</router-link>.
                    Spese accessorie: spesa di istruttoria € 0,00, spesa di incasso
                    rata € 0,00, spesa per Rendiconto periodico € 0,00, imposta sostitutiva sul contratto € 12,50, spese
                    invio Rendiconto € 0,00 oltre imposta di bollo pari a € 0,00 per importi superiori a € 77,47 per
                    ogni invio. Totale da rimborsare € 5.841,50 (Importo totale dovuto).<br>
                    Le condizioni economiche del prestito personale potranno variare se il richiedente non possiede i
                    <router-link @click="$emit('close')" to="promo-terms" class="underline">requisiti richiesti</router-link>.
                    Se il profilo del cliente non soddisfa i requisiti l'offerta verrà modificata e potrà avere un TAEG
                    massimo al 13,37%. In tali casi verranno applicate le seguenti condizioni: importo pari a € 5.000
                    (Importo totale del credito) da rimborsare in 60 mesi, con rate mensili di € 112,49, TAN fisso
                    12,50% - TAEG 13,37%. Spese accessorie: spesa di istruttoria € 0,00, spesa di incasso rata € 0,00,
                    spesa per Rendiconto periodico € 0,00, imposta sostitutiva sul contratto € 12,50, spese invio
                    Rendiconto € 0,00 oltre imposta di bollo pari a € 0,00 per importi superiori a € 77,47 per ogni
                    invio.
                    Totale da rimborsare € 6.761,90 (Importo totale dovuto).
                    TAEG massimo applicabile per offerte di prestito personale diverse dall'esempio su indicato: 14,38%.
                </p>
                <p class="mb-5">
                    Per tutte le condizioni economiche e contrattuali della Linea di
                    Credito Revolving, fare riferimento alle Informazioni Europee di Base
                    sul Credito ai Consumatori (cd. SECCI) sul sito
                    <a href="https://www.cofidis.it/trasparenza" target="_blank" class="">www.cofidis.it/trasparenza</a>.<br />
                    Esempio rappresentativo con TAEG calcolato sulla base delle ipotesi che l’Importo totale del credito, fornito per un anno,
                    sia utilizzato da subito e per intero al primo utilizzo; che sia applicato unicamente il Tasso Standard e non anche quello promozionale;
                    che il Contratto resti valido e che il consumatore adempia ai propri obblighi nei termini ed entro le date convenute nel Contratto;
                    che siano pagate rate mensili conteggiate suddividendo l’importo totale del credito in 12 quote uguali (quota capitale costante);
                    ad ogni quota è aggiunta una componente di interessi variabile maturata mensilmente e calcolata sul capitale residuo riferito al
                    periodo precedente, sulla base del TAN FISSO - Importo totale del credito €2.000 - rate minime mensili €60 da rimborsare mediante
                    addebito in conto corrente - TAN fisso standard 19,80% - TAEG 21,70%. Spese di istruttoria €0; spese di incasso rata €0;
                    imposta di bollo sul contratto/imposta sostitutiva €0; spese di invio Rendiconto mensile €0; imposta di bollo su Rendiconto mensile €0.
                    Importo totale dovuto €2.214,50.
                    Esempio di primo utilizzo della Linea di Credito Revolving con tasso promozionale valido per il solo importo di € 600 in 10 rate:
                    10 rate da € 60 cadauna. Importo Totale dovuto € 600. TAN fisso 0%. TAEG 0%.<br />
                    La richiesta di finanziamento e di ogni utilizzo successivo sono soggette alla valutazione da parte di Cofidis S.A.
                </p>
                <p class="mb-5">
                    Cessione del Quinto, informazioni per il consumatore: TAEG massimo come da indicazioni di Banca d'Italia 20,11% per finanziamenti inferiori a Euro 15.000, 15,06% per
                    finanziamenti superiori a Euro 15.000. Esempio di prestito: rata Euro 250,00, durata del finanziamento 120 mesi, importo totale dovuto dal consumatore Euro 30.000,00, TAEG
                    7,05%, TAN 6,81%, Oneri Fiscali Euro 16,00. Netto ricavo al Cliente Euro 21.697,52. L’esempio indicato si riferisce a un dipendente statale avente, all’inizio del periodo di
                    ammortamento, 40 anni di età e 20 anni di anzianità lavorativa.<br />
                    Per tutte le condizioni economiche e contrattuali, fare riferimento alle informazioni Europee di Base sul Credito ai Consumatori (cd. SECCI) sul sito
                    <a href="https://www.cofidis.it/" target="_blank" class="">www.cofidis.it</a>. Per il
                    perfezionamento del contratto di prestito contro cessione del quinto dello stipendio / pensione occorre anche il benestare da parte dell’ente pensionistico e del datore di lavoro e
                    l’assenso alla conclusione di un contratto di assicurazione sulla vita con costi a carico di Cofidis, nella quale Cofidis rivestirà la qualifica di Contraente / Beneficiario ed il Cliente
                    la qualifica di Assicurato. Per il contratto di prestito personale contro cessione del quinto dello stipendio, Cofidis S.A. stipula anche direttamente, e con costi a proprio carico,
                    polizza a tutela del rischio impiego. La richiesta di prestito è soggetta alla valutazione da parte di Cofidis S.A.
                </p>
                <p id="guide_pdf" class="mb-5">
                    <a href="https://www.cofidis.it/trasparenza/" class="underline" target="_blank">Trasparenza</a>
                </p>
                <p class="my-3">
                    <b>Cofidis Italia</b><br />&copy; Copyright {{ year }}
                </p>
                <p class="mb-3">
                    Capitale Sociale 67.500.000€ i.v., Iscritta all’Albo Banche ed altri intermediari esteri abilitati in Italia al nr. 8101 - Società soggetta a direzione e coordinamento di Banque Fédérative du Crédit Mutuel S.A. – soggetta alla vigilanza della Banca di Francia (Autorité de Contrôle Prudentiel et de Résolution) e, in qualità di succursale italiana di banca comunitaria, alla vigilanza della Banca d’Italia, per le materie a questa demandate dalle vigenti disposizioni normative. Abilitata all’esercizio dell’attività di intermediazione assicurativa in Italia, iscritta nell’Elenco degli Intermediari dell’Unione Europea annesso al Registro degli Intermediari Assicurativi nr. UE00012402, soggetta a vigilanza dell’ AUTORITÉ DE CONTROLE PRUDENTIEL (ACP).
                </p>
                <p class="mt-4">
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline text-sm" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline text-sm" target="_blank">Accessibilità: non conforme</a>
                </p>
            </div>
        </div>
    </footer>
</template>
<script>
import $ from "jquery";
import ScrollTopButton from "@/components/scrollTopButton.vue";
import { mapUserData } from "@/utils";

export default {
    components: { ScrollTopButton },
    data() {
        return {
            year: null,
        };
    },
    computed: {
        ...mapUserData(["exp_date"])
    },
    mounted() {
        $(document).on("click", ".to-top", function () {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
        });

        this.year = new Date().getFullYear();
    }
};
</script>
