<template>
    <div id="wa-qr-popup" class="wa-popup fixed right-2 md:right-4 bottom-6 transition-opacity duration-500" :class="{'opacity-0': !showPopup}">
        <div 
            @click="handleClose"
            v-show="isMobile && !isClosed || !isMobile && showQR"
            class="absolute right-0 -top-2 transform -translate-y-9 shadow-lg rounded-full cursor-pointer"
        >
            <img height="30" width="30" src="@/assets/images/icon-close-dark.svg" />
        </div>
        <div 
            v-show="!showQR" 
            class="cursor-pointer bg-green-1 pl-3 pr-2 py-2 lg:pl-4 rounded-xl shadow gap-3 inline-flex items-center transform transition-transform duration-700"
            :class="{'pr-4 translate-x-4': isClosed}"
            @click="handleClick"
        >
            <p class="text-white font-bold mb-1 lg:mb-0 text-lg leading-tight" :class="{'hidden': isClosed}" >
                Serve <br class="md:hidden" /> 
                aiuto?
            </p>
            <img height="30" width="30" src="@/assets/images/icon-wa.svg" />
        </div>
        <div v-show="showQR">
            <div class="relative flex border-green-1 bg-white border-2 p-2 rounded-xl shadow">
                <div class="ml-1 mr-2 mt-2">
                    <img height="45" width="45" src="@/assets/images/icon-wa-green.svg" />
                </div>
                <div class="flex flex-col my-2 justify-start mr-4">
                    <p class="text-lg font-bold leading-tight">Assistente virtuale<br> WhatsApp</p>
                    <p class="text-base leading-tight mt-2">Inquadra il QR code<br> e ricevi subito aiuto</p>
                </div>
                <div>
                    <img width="110" height="110" src="@/assets/images/wa-qr-code.png" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "WaWidget",
    data() {
        return {
            showPopup: false,
            showPopupTime: 1000,
            showQR: false,
            isClosed: false,
            whatsappUrl: "https://wa.me/message/NV3CHYU2DFD2I1"
        }
    },
    computed: {
        isMobile() {
            return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent);
        },
    },
    mounted() {
        setTimeout(
            () => { this.showPopup = true },
            this.showPopupTime
        )
    },
    methods: {
        handleClick() {
            if (this.isMobile && this.isClosed)
                this.isClosed = false;
            else if (this.isMobile)
                window.open(this.whatsappUrl);
            else
                this.showQR = true;
        },
        handleClose() {
            if (this.isMobile)
                this.isClosed = true;
            else
                this.showQR = false;
        }
    }
}

</script>