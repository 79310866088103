<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative" :style="bgHeader">
                <LogoPowerCredit />
            </header>

            <div class="form w-full mx-auto text-center px-6 lg:px-0 mb-14">
                <!-- Hero -->
                <div class="bg-white relative rounded-3xl shadow-2xl mx-auto -mt-16 sm:-mt-40">
                    <form @submit.prevent>
                        <div class="px-4 pt-4 md:pt-0">
                            <p class="text-xl text-gray-5 font-semibold pt-2 sm:pt-6 px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                            <p class="font-medium px-12 mt-1">per te che sei cliente Cofidis</p>
                            <div class="offer-wrapper mt-5 border border-yellow rounded-t-2xl rounded-b-lg md:rounded-b-2xl overflow-hidden mx-auto">
                                <div class="text-center bg-yellow pt-1 pb-2 md:pb-1 px-2">
                                    <h1 class="text-6xl sm:text-7xl font-bold">{{ importo|num }}€</h1>
                                    <p class="font-bold lg:text-lg leading-tight mt-0.5"><span class="block md:inline">pronti all’uso </span>in 24h sul tuo conto corrente*</p>
                                </div>
                                <p class="px-3 pt-2 leading-tight text-sm xl:px-8">+ attivazione del Servizio CLUB a {{ monthlyClubPrice }} euro al mese con addebito annuale</p>
                                <ClubToggle class="pt-7 pb-12">
                                    <p class="text-xs">L’acquisto del servizio CLUB è facoltativo e non è necessario per ottenere la Linea di Credito, né per ottenerla a condizioni più vantaggiose. Puoi visionare qui il <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a> e l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.</p>
                                    <p class="text-xs mt-2">Il servizio è finanziato mediante apposito utilizzo della Linea di Credito Revolving concessa da Cofidis. Il Cliente autorizza Cofidis a procedere al pagamento per proprio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l per l’erogazione del Servizio.</p>
                                </ClubToggle>
                            </div>  
                        </div> 
                        <a @click="submit(true)" class="block cta first-cta text-lg rounded-full px-10 py-2 z-50 cursor-pointer bg-green-1 text-white">
                            <span class="block text-center text-xl font-semibold">ATTIVA ORA</span>
                            <span class="block font-light text-base text-center -mt-1">Solo fino al <strong class="font-bold uppercase">{{ expDate }}</strong></span>
                        </a>
                    </form>
                    <div class="text-center py-4 md:py-8">
                        <a class="underline cursor-pointer" @click="submit(false)">
                            Richiedi senza CLUB
                        </a>
                    </div>
                    <div class="section-info flex flex-wrap rounded-b-3xl mt-6 px-2">
                        <div class="w-1/3 text-grey-5 text-center py-5">
                            <i class="icon nodocument align-middle w-12 h-12 block mx-auto"></i>
                            <h4 class="block text-base xl:inline-block lg:px-2 mt-3 leading-none">Senza busta paga</h4>
                        </div>
                        <div class="w-1/3 text-grey-5 text-center py-5">
                            <i class="icon free align-middle w-12 h-12 block mx-auto"></i>
                            <h4 class="block text-base xl:inline-block lg:px-2 mt-3 leading-none">Attivazione gratuita</h4>
                        </div>
                        <div class="w-1/3 text-grey-5 text-center py-5">
                            <i class="icon veloce align-middle w-12 h-12 block mx-auto"></i>
                            <h4 class="block text-base xl:inline-block lg:px-2 mt-3 leading-none">Esito immediato</h4>
                        </div>
                    </div>
                </div>
                <div class="w-auto text-left text-sm mt-4">*Salvo approvazione di Cofidis, in 24h lavorative dal ricevimento della documentazione completa e corretta.</div>
            </div>

            <!-- Section vantaggi -->
            <section class="section-feat py-6 text-center cursor-pointer" @click="scrollToAnchor()">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <h3 class="w-3/4 flex-grow font-semibold text-lg text-left px-2">Scopri tutti i vantaggi di Power Credit.</h3>
                        <i class="icon arrowdown"></i>
                    </div>
                </div>
            </section>

            <!-- Section claim -->
            <section class="py-6">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <p class="w-3/4 flex-grow text-lg text-left px-2">Power Credit è la Linea di Credito Ricaricabile disponibile per i tuoi utilizzi.</p>
                    </div>
                </div>
            </section>

            <!-- Section passaggi -->
            <section class="section-passages bg-white py-6">
                <div class="sm:container p-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">
                        Come attivarla?<br>
                        Bastano pochi passaggi
                    </h3>
                    <!-- Elenco puntato passaggi -->
                    <div class="relative w-full xl:w-2/3 mx-auto">
                        <div class="absolute h-4/5 top-2 left-4 sm:top-0 sm:h-full w-0.5 bg-yellow  z-0"></div>
                            
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">1</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Controlla i tuoi dati </p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center ">
                                <span class="align-middle font-semibold">2</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Invia la richiesta di Power Credit</p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">3</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Attendi l’esito </p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">4</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Firma la richiesta di finanziamento digitalmente</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section faq -->
            <Accordion id="anchor" title="Come funziona Power Credit?" :tabs="[
                {
                    title: 'La Linea di Credito Ricaricabile è sempre disponibile online.', 
                    content: `Power Credit è semplice! Apri la tua Linea di Credito da 2.000€ e chiedi subito di ricevere la somma di ${ $options.filters.num(importo) }€ sul tuo conto corrente. Poi ricarichi Power Credit grazie alle rate fisse mensili di soli 60€. Così puoi tenere 
                                    sotto controllo le tue uscite!`
                },
                {
                    title: 'L’attivazione è gratuita. Il primo utilizzo è in promozione per te.', 
                    content: `Solo per te c’è un ulteriore vantaggio: <strong class='font-bold'>TAN promozionale 0% sulle prime ${ prices[activeSolutionIndex].rate } rate rimborsate.</strong> Dal 4° mese in poi, 
                    valgono le condizioni economiche standard*.
                    <br><br>
                    *TAN fisso standard ${ $options.filters.num2(prices[activeSolutionIndex].tan) }% - TAEG ${ $options.filters.num2(prices[activeSolutionIndex].taeg) }%`
                }]" 
            />

            <!-- Section contest -->
            <ClubContest>
                <p class="text-base mt-4">
                    *Concorso valido dal 11/11/2024 al 30/10/2025. 
                    <a href="https://www.cofidis.it/it/download/trasparenza/regolamento-concorso-cofidis-ti-premia.pdf" class="underline" target="_blank">Regolamento concorso</a>.
                </p>
            </ClubContest>

            <!-- Section widget -->
            <section class="section-widget md:mt-0 py-6 sm:py-10 lg:py-8 bg-white">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-4 sm:mb-8">
                        <h3 class="font-semibold text-4xl text-gray-5 leading-none">
                            Cosa dicono i <br class="sm:hidden" />
                            nostri clienti
                        </h3>
                    </div>
                    <Trustpilot />
                </div>
            </section>

            <!-- Section trust -->
            <section class="bg-white py-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Cofidis vuol dire fiducia!</h3>
                    <p class="text-base font-medium px-4">Siamo <strong class="font-bold">il credito al consumo che dà e ispira fiducia immediata</strong> grazie a soluzioni semplici, veloci e 100% digitali in grado di
                        fornire un aiuto immediato nella vita delle persone.</p>
                    <p class="text-base font-medium px-4 mt-4">Proprio come abbiamo fatto con te, che <strong class="font-bold">ci hai già scelto</strong> per acquistare ciò che ami. Ora vogliamo darti ulteriore fiducia
                        con questa <strong class="font-bold">soluzione pensata per te.</strong></p>
                </div>
            </section>

            <!-- Section vantaggi -->
            <section class="section-feat2 p-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Tanti vantaggi in un lampo</h3>
                    <div class="advantages-block rounded-3xl shadow-2xl md:flex p-5 mb-4">
                        <div class="p-5 text-center border-b md:border-b-0 md:border-r border-solid border-black border-opacity-25">
                            <i class="icon nodocument align-middle w-16 h-16 mb-3 block mx-auto"></i>
                            <h3 class="font-semibold text-2xl text-gray-5 mb-3">Senza busta paga</h3>
                            <p class="text-base font-medium">Non ti chiediamo nessun documento di reddito: ci bastano pochi dati e la tua parola.</p>
                        </div>
                        <div class="p-5 text-center border-b md:border-b-0 md:border-r border-solid border-black border-opacity-25">
                            <i class="icon free align-middle w-16 h-16 mb-3 block mx-auto"></i>
                            <h3 class="font-semibold text-2xl text-gray-5 mb-3">Attivazione gratuita</h3>
                            <p class="text-base font-medium">L’attivazione è gratuita. Il primo utilizzo è in promozione per te.</p>
                        </div>
                        <div class="p-5 text-center">
                            <i class="icon veloce align-middle w-16 h-16 mb-3 block mx-auto"></i>
                            <h3 class="font-semibold text-2xl text-gray-5 mb-3">Esito immediato</h3>
                            <p class="text-base font-medium">Bastano 2 click per inviare la richiesta di Power Credit e per ricevere l’esito.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>
<script>
import Loader from "@/components/Loader"
import LogoPowerCredit from "@/components/LogoPowerCredit"
import Accordion from "@/components/Accordion"
import loadData from "@/dataService.js";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment/moment";
import { mapUserData } from "@/utils";
import Trustpilot from "@/components/TrustpilotSwiper.vue";
import ClubToggle from "@/components/ClubToggle.vue";
import ClubContest from "@/components/ClubContest.vue";

const redirectHomepage = () => {    
    location.href = '/powercredit-club'
}

export default {
    name: "Home",
    components: { Loader, Trustpilot, ClubToggle, LogoPowerCredit, Accordion, ClubContest },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_eleg_power)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        async submit(wantClub) {
            window.removeEventListener('popstate', redirectHomepage)
            this.$store.commit("setUserData", { key: 'rate', value: this.prices[this.activeSolutionIndex].rate });
            if (wantClub) {
                await this.$store.dispatch("sendData", { step: "dati_anag" });
                this.$store.dispatch("redirect", { step: "homepage", route: "ClubPowerConsent" });
            } else {
                this.$store.dispatch("sendData", { step: "club_warning" });
                this.$store.dispatch("redirect", { step: "homepage", route: "ClubPowerWarning" });
            }
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        },
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        ...mapUserData(["name", "service_amount"]),
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`                
            }
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        },
        monthlyClubPrice() {
            return this.service_amount % 12 === 0 ? this.service_amount / 12 : parseFloat(this.service_amount / 12).toFixed(2);
        },
    },
    data() {
        return {
            loading: true,
            expDate: null,
            importo: 1800,
            activeSolutionIndex: 0,
            prices: loadData("price.json").prices.reverse(),
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/powercredit-club",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
        };
    },
    async created() {
        this.reloadOnBrowserBack()

        // reload from thank you page
        if(localStorage.getItem('powercredit_cl_metlife')) {
            this.$store.dispatch("redirect", {step: "income", route: "TypMetlife"});
            return;
        }
        if(localStorage.getItem('powercredit_cl_request_evaluation')) {
            this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            return;
        }

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('powercredit_cl_user_id')
            codeCampaign = localStorage.getItem('powercredit_cl_code_campaign')
            trackCode = localStorage.getItem('powercredit_cl_track_code') ?? null
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('powercredit_cl_user_id', userId)
            localStorage.setItem('powercredit_cl_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('powercredit_cl_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode});
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'prod', value: data.Prod });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'service', value: 0 });
            this.$store.commit("setUserData", { key: 'service_pack', value: data.service_pack });
            this.$store.commit("setUserData", { key: 'service_amount', value: data.im_pacchetto });
            this.$store.commit("setUserData", { key: 'importo', value: this.importo });
            this.$store.commit("setUserData", { key: 'is_creditline', value: +data.flag_creditline });

            // address
            this.$store.commit("setUserData", { key: 'address', value: data.Indirizzo });
            this.$store.commit("setUserData", { key: 'address_city', value: `${data.City} (${data.Prov})`});
            this.$store.commit("setUserData", { key: 'address_cap', value: data.CAP });

            // set expiry date
            this.expDate = moment(data.dt_end_eleg_power).locale("it").format('D MMMM')
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_eleg_power).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }
            // failed procedure
            if(data.page_block && data.page_block === 'reddito_not_allowed') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                return;
            }

            // check expired document
            const isDocExpired = moment(data.data_scadenza_documento) < moment();
            this.$store.commit("setUserData", { key: 'document_expired', value: isDocExpired });

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

        } catch (error) {
            // reload from thank you page
            if(localStorage.getItem('powercredit_cl_metlife')) {
                this.$store.dispatch("redirect", {step: "income", route: "TypMetlife"});
            }
            else if(localStorage.getItem('powercredit_cl_request_evaluation')) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            }
            else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            }
        }
        this.loading = false;
    },
    async mounted() {
        // animation interesection
        window.onscroll = function() {
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')
                }
            });
        }
    }
};
</script>