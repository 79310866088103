<template>
    <main>
        <Loader v-if="loading" />
        
        <div v-else>
            <!-- Header -->
            <header class="w-full relative" :style="bgHeader">
                <Logo :fullScreen="true" />
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl pl-5 2xl:px-0 mt-10 sm:mt-18 lg:mt-3">
                    <h1 class="md:px-14 lg:px-2 ml-2">
                        <span class="w-fit slanted sm:ml-4 px-4 lg:pl-5">4.000€ in 60 mesi</span>
                        <span class="w-fit slanted sm:ml-4 px-4 lg:pl-3 lg:pr-5">TAEG 4,90%*</span>
                    </h1>
                </div>
            </header>
            <form class="w-full mx-auto text-center px-6 lg:px-0" @submit.prevent="submit">
                <div class="bg-white relative rounded-3xl shadow-2xl mx-auto pb-2 lg:pb-4 md:mb-6 -mt-12 sm:-mt-36 sm:max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <div class="px-4">
                        <p class="text-xl text-gray-5 font-semibold pt-6 px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                        <p class="font-medium mt-1">per te che sei già cliente</p>
                        <div class="max-w-md mx-auto mt-4">
                            <div class="mt-7">
                                <select id="credit-select" class="home-select w-48 border-2" v-if="maxCredit && prices.length > 1" v-model="choosenCredit" @change="activeSolutionIndex = $event.target.selectedIndex">
                                    <option v-for="price in prices" :key="price.imp_finanz" :value="price.imp_finanz">{{ price.imp_finanz|num }}€<span v-if="price.imp_finanz == promo">*</span></option>
                                </select>
                                <div class="home-single-amount w-48 border-2 text-2xl" v-else-if="maxCredit">{{ maxCredit|num }}€<span v-if="maxCredit == promo">*</span></div>
                            </div>
                            <button class="w-full md:w-fit block rounded-full px-12 pb-2 pt-3 bg-green-1 text-white mt-2 shadow-xl mx-auto" type="submit">
                                <span class="block font-semibold text-xl leading-5 uppercase">Richiedi ora</span>
                                <span class="block leading-5 font-light text-base text-center">Solo fino al <strong class="font-bold uppercase">{{ expItaDate }}</strong></span>
                            </button>
                        </div>
                        <p class="font-semibold text-lg mt-9 lg:mt-8">Realizza i tuoi progetti</p>
                    </div>
                    <div class="flex flex-wrap items-start pt-3 pb-4 lg:pb-2 px-4 lg:px-40">
                        <!-- Icon 1 -->
                        <div class="w-1/3 flex flex-col items-center my-4 px-3">
                            <div class="bg-gray-1 rounded-full align-middle mb-1 sm:mt-0 aspect-square w-14 h-14 flex items-center justify-center">
                                <i class="icon nodocument inline-block w-full"></i>
                            </div>
                            <h4 class="block text-sm xl:inline-block lg:px-2 mt-1">Senza busta paga</h4>
                        </div>
                        <!-- Icon 2   -->
                        <div class="w-1/3 flex flex-col items-center my-4 px-4">
                            <div class="bg-gray-1 rounded-full align-middle mb-1 sm:mt-0 aspect-square w-14 h-14 flex items-center justify-center">
                                <i class="icon thunder inline-block w-full"></i>
                            </div>
                            <h4 class="block text-sm xl:inline-block lg:px-2 mt-1"> Esito immediato</h4>
                        </div>
                        <!-- Icon 3 -->
                        <div class="w-1/3 flex flex-col items-center my-4 px-3">
                            <div class="bg-gray-1 rounded-full align-middle mb-1 sm:mt-0 aspect-square w-14 h-14 flex items-center justify-center">
                                <i class="icon approved inline-block w-full"></i>
                            </div>
                            <h4 class="block text-sm xl:inline-block lg:px-2 mt-1"> Prestito pre-approvato</h4>
                        </div>
                    </div>
                </div>
                <section class="section-disclaimer mb-8 md:mb-6">
                    <div class="container text-center mx-auto mt-1">
                        <p class="w-full text-center text-gray-4 text-sm px-4 py-4">
                            *Messaggio pubblicitario con finalità promozionale - Offerta valida fino al {{ expFullDate }}
                            <svg @click="showPromoModal" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline-block ml-1 -mt-1 cursor-pointer w-3 h-3">
                                <circle cx="8.59766" cy="8.09766" r="7.40845" stroke="#252525" stroke-width="0.987793"/>
                                <path d="M8.00391 12.0494V6.6165H9.18827V12.0494H8.00391ZM8.00391 5.69292V4.44336H9.18827V5.69292H8.00391Z" fill="#252525"/>
                            </svg>
                        </p>
                    </div>
                </section>
            </form>

            <!-- Section vantaggi -->
            <section class="section-feat py-6 text-center cursor-pointer" @click="scrollToAnchor()">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <h3 class="w-3/4 flex-grow font-semibold text-lg text-left px-2">Scopri tutti i vantaggi del Prestito Personale pensato per te</h3>
                        <i class="icon arrowdown"></i>
                    </div>
                </div>
            </section>

            <!-- Section steps -->
            <section class="section-steps bg-gray-5 py-6 sm:py-10 lg:py-8">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-8 sm:mb-6">
                        <h3 class="font-semibold text-3xl text-white">
                            Come attivarlo?<br class="sm:hidden" />
                            Bastano pochi passaggi
                        </h3>
                    </div>
                    <!-- Steps -->
                    <div class="flex flex-wrap">
                        <!-- Step 1 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1">
                                <span class="block font-bold text-sm text-gray-4">Step 1</span>
                                <p class="font-semibold text-3xl text-red-1">I tuoi dati</p>
                                <p class="font-medium text-gray-4">Completa i tuoi dati e invia la richiesta</p>
                            </div>
                        </div>
                        <!-- Step 2 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto
                                animate__animated animateFadeInUp animate__2">
                                <span class="block font-bold text-sm text-gray-4">Step 2</span>
                                <p class="font-semibold text-3xl text-red-1">Esito</p>
                                <p class="font-medium text-gray-4">Ricevi l’esito immediato</p>
                            </div>
                        </div>
                        <!-- Step 3 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                                <span class="block font-bold text-sm text-gray-4">Step 3</span>
                                <p class="font-semibold text-3xl text-red-1">Firma digitale</p>
                                <p class="font-medium text-gray-4">Firma il contratto direttamente dal tuo smartphone</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Section contest -->
            <ClubContest>
                <p class="text-base mt-4">
                    *Concorso valido dal 11/11/2024 al 30/10/2025. 
                    <a href="https://www.cofidis.it/it/download/trasparenza/regolamento-concorso-cofidis-ti-premia.pdf" class="underline" target="_blank">Regolamento concorso</a>.
                </p>
            </ClubContest>

            <!-- Section widget -->
            <section class="section-widget md:mt-0 py-6 sm:py-10 lg:py-8 bg-white">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-4 sm:mb-8">
                        <h3 class="font-semibold text-4xl text-gray-5 leading-none">
                            Cosa dicono i <br class="sm:hidden" />
                            nostri clienti
                        </h3>
                    </div>
                    <Trustpilot />
                </div>
            </section>

            <!-- Section trust -->
            <section id="anchor" class="section-feat2 py-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Cofidis vuol dire fiducia!</h3>
                    <p class="text-base font-medium px-4">Siamo <strong class="font-bold">il credito al consumo che dà e ispira fiducia immediata</strong> grazie a soluzioni semplici, veloci e 100% digitali in grado di
                        fornire un aiuto immediato nella vita delle persone.</p>
                    <p class="text-base font-medium px-4 mt-4">Proprio come abbiamo fatto con te, che <strong class="font-bold">ci hai già scelto</strong> per acquistare ciò che ami. Ora vogliamo darti ulteriore fiducia
                        con questa <strong class="font-bold">soluzione pensata per te.</strong></p>
                </div>
            </section>

            <!-- Section faq -->
            <section v-if="prices.length" class="section-faq text-center">
                <div class="sm:container px-6 py-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-6 text-center">
                        Come funziona?
                    </h3>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 0 }" @click="toggleFaq(0)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">Il Prestito Personale pre-approvato e senza busta paga</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(0)">
                                    <svg v-if="activeFaq != 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 0">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Il Prestito Personale pre-approvato è il prodotto finanziario riservato ai nostri migliori clienti che ti permette di inviare una richiesta di
                                    Prestito Personale da {{ choosenCredit|num }}€ in pochi click senza bisogno di fornire i documenti di reddito. Così risparmi tempo.
                                    Non ci sono spese di apertura pratica.
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 1 }" @click="toggleFaq(1)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">Bastano pochi click e l’esito è immediato</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(1)">
                                    <svg v-if="activeFaq != 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 1">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Per richiedere il Prestito Personale ti bastano pochi click ovunque ti trovi e le condizioni sono chiare e trasparenti:
                                    TAN fisso del {{ prices[activeSolutionIndex].tan|num }}% e TAEG del {{ prices[activeSolutionIndex].taeg|num2 }}%.
                                    Restituisci l’importo in {{ prices[activeSolutionIndex].rate }} piccole rate da {{ prices[activeSolutionIndex].imp_rata|num2 }}€.
                                    Ricevi un primo esito immediato e firmi digitalmente online la richiesta di Prestito Personale
                                    (<a :href="secciUrl" class="underline" target="_blank">SECCI</a>).
                                    <span v-if="choosenCredit == $store.state.promo">
                                        Le condizioni economiche dell’offerta promozionale possono variare in base al profilo del richiedente. Se il profilo non soddisfa i requisiti, il TAEG proposto sarà del {{promoKO.taeg}}% 
                                        (<a :href="promoKO.secciUrl" class="underline" target="_blank">SECCI</a>).
                                    </span>
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                </div>
            </section>

            <a @click="submit()" id="sticky-cta" class="w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 z-50 cursor-pointer bg-green-1">
                <span class="block text-center text-xl text-white">RICHIEDI ORA</span>
                <span class="block font-light text-base text-center -mt-1 text-white">Solo fino al <strong class="font-bold uppercase">{{ expItaDate }}</strong></span>
            </a>

        </div>

    </main>
</template>
<script>
// @ is an alias to /src
import Trustpilot from "@/components/TrustpilotSwiper"
import Loader from "@/components/Loader"
import Logo from "@/components/Logo"
import ClubContest from "@/components/ClubContest"
import loadData from "@/dataService.js";
import { mapGetters } from "vuex";
import { mapUserData } from "@/utils";
import axios from "axios";
import moment from "moment/moment";

const redirectHomepage = () => {    
    location.href = '/fs'
}

export default {
    name: "Home",
    components: { Loader, Trustpilot, Logo, ClubContest },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_eleg_fast)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },            
        async submit() {
            window.removeEventListener('popstate', redirectHomepage)
            this.$store.commit("setUserData", { key: 'importo', value: this.choosenCredit });
            this.$store.commit("setUserData", { key: 'rate', value: this.numInstallments });
            this.$store.dispatch("sendData", { step: "dati_anag" });
            if (this.choosenCredit == this.promoCredit ) {
                this.$store.dispatch("redirect", { step: "homepage", route: "RataFastCredit" });
            } else {
                this.$store.dispatch("redirect", { step: "homepage", route: "IncomeFastCredit" });
            }
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        },
        scrollAnimations() {
            // steps animation
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            if (!animateFadeInUp.length) return

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')
                }
            });

            // sticky cta
            if ( window.scrollY > window.innerHeight / 1.5 && !this.showStickyCta || window.scrollY <= window.innerHeight / 1.5 && this.showStickyCta ) {
                document.querySelector('#sticky-cta').classList.toggle('show')
                this.showStickyCta = ! this.showStickyCta 
            }
        }
    },
    computed: {
        ...mapGetters(["uniquePrices", "promoKO"]),
        ...mapUserData(["exp_date"]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        promoModel() {
            return this.getPromoOffer(false);
        },
        promoCredit() {
            return this.$store.state.promo
        },
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") center top no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        bgBanner() {
            return {
                '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner.png") 60% top no-repeat`,
                '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.png") 60% top no-repeat`,
            }
        },
        numInstallments() {
            return this.prices[this.activeSolutionIndex].rate
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        },
        expItaDate() {
            return moment(this.exp_date).locale("it").format('D MMMM')
        },
        expFullDate() {
            return moment(this.exp_date).locale("it").format('D/M/Y')
        },
        secciUrl() {
            const credit = this.$store.state.choosenCredit
            const baseUrl = this.$store.state.secciUrl
            if (credit)
                return `${baseUrl}Secci${credit.toString()[0]}k.pdf`
            else
                return ``
        }
    },
    data() {
        return {
            name: '',
            loading: true,
            expDate: null,
            activeFaq: null,
            activeSolutionIndex: 0,
            maxCredit: this.$store.state.userData.max_credit ?? null,
            prices: loadData("price.json").prices,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/fastcredit",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common"
        };
    },
    async created() {        

        // if max credit already set, i already have all necessary values in the store
        if(this.maxCredit) {
            this.name = this.$store.state.userData.name
            this.prices = this.prices.filter(obj => { return obj.imp_finanz <= this.maxCredit }).reverse()
            this.loading = false;
            return;
        }

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('fastcredit_user_id')
            codeCampaign = localStorage.getItem('fastcredit_code_campaign')
            trackCode = localStorage.getItem('fastcredit_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('fastcredit_user_id', userId)
            localStorage.setItem('fastcredit_code_campaign', codeCampaign)
            localStorage.setItem('fastcredit_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode})            
            
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });

            this.$store.commit("setUserData", { key: 'iniz', value: data.Iniz });
            this.$store.commit("setUserData", { key: 'piano_f', value: data.PianoF });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'is_creditline', value: +data.flag_creditline });
            
            // address
            this.$store.commit("setUserData", { key: 'address', value: data.Indirizzo });
            this.$store.commit("setUserData", { key: 'address_city', value: `${data.City} (${data.Prov})`});
            this.$store.commit("setUserData", { key: 'address_cap', value: data.CAP });

            // set name
            this.name = data.Nome

            // set expiry date
            this.expDate = data.dt_end_eleg_fast
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_eleg_fast).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }
            // failed prodecure with metlife
            if(data.page_block && data.page_block === 'reddito_not_allowed' && localStorage.getItem('fastcredit_typ_metlife')) {
                this.$store.dispatch("redirect", {step: "income", route: "TypMetlife"});
                return;
            }
            // failed procedure
            if(data.page_block && data.page_block === 'reddito_not_allowed') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                return;
            }
            // set max credit available
            this.maxCredit = parseInt(data.Importo)
            this.$store.commit("setUserData", { key: 'max_credit', value: this.maxCredit });

            // update pricing
            this.prices = this.prices.filter(obj => { return obj.imp_finanz <= this.maxCredit }).reverse();

            const defaultCredit = loadData("price.json").default_credit
            this.$store.commit("setChoosenCredit", defaultCredit)

            this.activeSolutionIndex = this.prices.findIndex( obj => obj.imp_finanz === defaultCredit )

            // check expired document
            if(moment(data.data_scadenza_documento) < moment()) {
                this.$store.commit("setUserData", { key: 'document_expired', value: true });
            }
            else {
                this.$store.commit("setUserData", { key: 'document_expired', value: false });
            }                        

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

        } catch (error) {
            // reload from thank you page
            if (localStorage.getItem('fastcredit_request_evaluaton') && localStorage.getItem('fastcredit_typ_metlife')) {
                this.$store.dispatch("redirect", {step: "income", route: "TypMetlife"});
            }
            else if(localStorage.getItem('fastcredit_request_evaluaton')) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            }
            else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            }
        }                
        this.loading = false;
    },
    async mounted() {                    
        if ( window.scrollY <= window.innerHeight / 1.5 && this.showStickyCta ) {
            document.querySelector('#sticky-cta').classList.add('show')
        }

        window.addEventListener('scroll', this.scrollAnimations, {passive: true} )
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollAnimations)
    }
};
</script>