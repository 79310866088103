<template>
    <footer class="bg-white">
      <div class="w-full p-6 mx-auto sm:max-w-7xl relative">
          <ScrollTopButton />
          <div class="footer-content mt-4 lg:mt-0">
              <div class="w-full">
                <p class="text-sm text-gray-5">Messaggio pubblicitario con finalità promozionale.</p>
                <p class="text-sm mb-4">
                    Concorso valido dal 11/11/2024 al 30/10/2025. 
                    <a href="https://www.cofidis.it/it/download/trasparenza/regolamento-concorso-cofidis-ti-premia.pdf" class="underline" target="_blank">Regolamento concorso</a>.
                </p>
                <div class="text-sm">
                  <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a> |
                  <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
                </div>
              </div>
          </div>
      </div>
  </footer>
</template>
<script>

import ScrollTopButton from '@/components/scrollTopButton.vue';

export default {
  name: "Footer",
  components: {ScrollTopButton}
};
</script>
