<template>
    <div class="fixed w-screen h-screen bg-gray-900 bg-opacity-90 sm:bg-opacity-70 top-0 left-0 bottom-0 right-0 z-50" @click.self="close">
        <div class="h-5/6 sm:h-auto overflow-y-scroll sm:overflow-auto absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg rounded-3xl p-4 bg-white text-left text-sm w-11/12 sm:w-2/3 2xl:w-1/2 z-20">
            <div class="w-full text-right text-xs pr-2">
                <button class="text-sm underline" @click="close">Chiudi</button>
            </div>
            <h3 class="text-lg font-bold text-center">Termini e condizioni</h3>
            <p class="mt-3 text-sm px-2 pb-6">
                Messaggio pubblicitario con finalità promozionale relativo all'offerta per un importo richiesto di
                {{ promoModel.imp_finanz_text }} con TAEG {{ promoModel.taeg }}% . Per tutte le condizioni economiche e contrattuali, fare riferimento alle
                informazioni Europee di Base sul Credito ai Consumatori (cd. SECCI) sul sito
                <a href="https://www.cofidis.it" target="_blank" class="underline">www.cofidis.it</a>.
                Esempio rappresentativo di un prestito personale di importo pari a {{ promoModel.imp_finanz_text }} (Importo totale del credito)
                da rimborsare in 60 mesi, con rate mensili di € {{ promoModel.imp_rata }},
                TAN fisso {{ promoModel.tan }}% - TAEG {{ promoModel.taeg }}%.
                Spese accessorie: spesa di istruttoria € 0, spesa di incasso rata € 0, spesa per rendiconto periodico € 0,
                imposta sostitutiva sul contratto € {{ promoModel.imposta }}, spese invio Rendiconto € 0,00 oltre imposta di bollo pari a € 0,00
                per importi superiori a € 77,47 per ogni invio.
                Totale da rimborsare {{ promoModel.dovuto }} € (Importo totale dovuto).
                La richiesta di finanziamento è soggetta alla valutazione da parte di Cofidis S.A.
                L'offerta promozionale con TAEG {{ promoModel.taeg }}% del prestito personale è valida fino al {{ expDate }} ed è
                disponibile solo per i già clienti Cofidis in possesso di <button @click.prevent="promoTerms()" class="underline line-break">determinati requisiti</button>. Se il profilo del
                cliente non soddisfa i requisiti le condizioni economiche dell'offerta verranno modificate e potrà
                essere applicato TAEG massimo fino al 13,37%.<br>
                In tali casi verranno applicate le seguenti condizioni: importo pari a {{ promoKO.imp_finanz_text }} (Importo totale del
                credito) da rimborsare in {{ promoKO.rate }} mesi, con rate mensili di € {{ promoKO.tot_rata }}, TAN fisso {{ promoKO.tan }}% - TAEG {{ promoKO.taeg }}%. Spese
                accessorie: spesa di istruttoria € 0,00, spesa di incasso rata € 0,00, spesa per Rendiconto periodico €
                0,00, imposta sostitutiva sul contratto € {{ promoKO.imposta }}, spese invio Rendiconto € 0,00 oltre imposta di bollo
                pari a € 0,00 per importi superiori a € 77,47 per ogni invio. Totale da rimborsare € {{ promoKO.dovuto }} (Importo
                totale dovuto).
            </p>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    computed: {
        promoModel() {
            return this.$store.getters.promoOffer({upgrade: false, services: false});
        },
        ...mapGetters(["promoKO"]),
        expDate() {
            const date = this.$store.state.userData.exp_date;
            return date.length < 11 ? date : moment(date).locale('it').format('DD/MM/Y')
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        promoTerms() {
            this.$emit('close');
            this.$store.dispatch("redirect", { step: 'promo_terms', route: 'PromoTerms' });
        }
    }
}
</script>
