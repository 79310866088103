<template>
    <div :class="{'opened' : toggleOpen}" class="club-toggle flex flex-col justify-center items-center">
        <div class="club-toggle-btn cursor-pointer" @click="toggleOpen = !toggleOpen">
            Cos'è CLUB?
            <span class="ml-3"></span>
        </div>
        <div class="toggle-mask overflow-hidden">
            <ul class="flex flex-col lg:flex-row lg:flex-wrap  items-center lg:items-start justify-center">
                <li class="club-toggle-content">
                    <div class="flex justify-center">
                        <img src="@/assets/images/icon-club-shopping.svg" />
                    </div>
                    <p><b>Offerte e gift card</b> per il tuo shopping sui prodotti dei <b>migliori brand</b></p>
                </li>
                <li class="club-toggle-content">
                    <div class="flex justify-center">
                        <img src="@/assets/images/icon-club-restaurant.svg" />
                    </div>
                    <p>Accesso a <b>tantissime attività per due al prezzo di uno</b> (+1.000 strutture convenzionate in tutta Italia)</p>
                </li>
                <li v-if="hasSalute" class="club-toggle-content">
                    <div class="flex justify-center">
                        <img src="@/assets/images/icon-club-salute.svg" />
                    </div>
                    <p><b>Sconti esclusivi (fino al 65%) su prestazioni sanitarie </b>disponibili in <b>+12.000 strutture</b> in tutta Italia</p>
                </li>
                <li v-else class="club-toggle-content travel">
                    <div class="flex justify-center">
                        <img src="@/assets/images/icon-club-travel.svg" />
                    </div>
                    <p><b>Sconti</b> per le tue vacanze sulle <b>principali piattaforme di viaggio</b></p>
                </li>
                <li class="pt-8 md:pt-11 px-3 lg:px-8">
                    <p class="text-base max-w-lg mx-auto">Ma c'è di più! Aderendo al servizio CLUB, ogni mese <strong>puoi provare a vincere un Buono Benzina</strong> dal valore di <strong>1.000€!</strong></p>
                    <p class="text-xs mt-3 mb-7">Per maggiori informazioni sul concorso consulta il <a href="https://www.cofidis.it/it/download/trasparenza/regolamento-concorso-cofidis-ti-premia.pdf" target="_blank" class="underline">regolamento</a>.</p>
                    <slot></slot>
                </li>
            </ul>
        </div> 
    </div>
</template>
<script>
    export default {
        name: "ClubToggle",
        data() {
            return {
                toggleOpen: false
            }
        },
        props: {
            hasSalute: {
                type: Boolean,
                required: false,
                default: true
            }
        }
    }
</script>