<template>
  <footer class="px-6 pb-8 pt-6 md:pt-2 mt-5">
    <div class="max-w-4xl xl:max-w-6xl mx-auto">
      <p class="text-sm text-gray-5">Messaggio pubblicitario con finalità promozionale.</p>
      <p class="text-sm mb-4">
          Concorso valido dal 11/11/2024 al 30/10/2025. 
          <a href="https://www.cofidis.it/it/download/trasparenza/regolamento-concorso-cofidis-ti-premia.pdf" class="underline" target="_blank">Regolamento concorso</a>.
      </p>
      <div class="text-sm">
        <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a> |
        <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
      </div>
    </div>
  </footer>
</template>
<script>

export default {
  name: "Footer",
};
</script>
