<template>
    <div id="content" :class="contentClass" class="sm:px-0 bg-form min-h-screen flex flex-col relative">
        <div id="logo" class="logo-wrapper bg-white py-2">
            <div class="w-11/12 sm:max-w-7xl mx-auto flex flex-wrap items-center">
                <div class="flex-shrink">
                    <img :alt="$t('Cofidis Logo')" :title="$t('Cofidis Logo')" class="h-14" :src="logoSrc" />
                </div>
                <SecureFormBadge v-if="showSecureFormBadge" />
            </div>
        </div>

        <PromoModal v-if="$store.state.promoModal" @close="$store.state.promoModal = false">
        </PromoModal>

        <SlideUpDown v-if="showFlashMessage" :active="flashMessage !== null" class="w-full bg-green-500 px-4">
            <p class="text-center text-white font-semibold p-2" v-html="flashMessage" />
        </SlideUpDown>

        <portal-target name="top-form"></portal-target>

        <div :class="containerClass">
            <Progress v-if="percentage" :percentage="percentage" :colors="progressColors" ></Progress>
            <router-view />
            <div v-if="showBackButton" class="text-center text-sm text-gray-700 mb-12 mt-4">
                <a href="#" @click.prevent="goBack()" class="back-btn underline">{{ $t("Torna indietro") }}</a>
            </div>
            <div v-else class="spacer h-6"></div>
        </div>

        <portal-target name="sub-form"></portal-target>

        <transition name="crm">
            <div v-if="showRedirectLanding" class="bg-black bg-opacity-25 absolute inset-0 flex items-start justify-center z-10">
                <div class="modal-wrapper bg-white py-2 px-4 sm:px-10 mx-4 text-center rounded-3xl mt-40 max-w-lg text-gray-700 shadow-xl">
                    <img class="block mx-auto sm:inline-block align-middle h-32" src="@/assets/images/alert-redirect.svg" />
                    <h1 class="text-2xl sm:text-3xl font-semibold">
                        <span v-if="$store.state.redirectType === 'fast'">{{ $t("Prestito personale pre-approvato e senza busta paga, in solo 1 minuto!") }}</span>
                        <span v-if="$store.state.redirectType === 'conso'">{{ $t("Prestito personale con consolidamento: una sola rata, zero pensieri!") }}</span>
                    </h1>
                    <p class="mb-6 mt-2 text-lg italic sm:px-12">Verrai reindirizzato all’<strong>offerta riservata ai nostri clienti</strong> in pochi secondi</p>
                    <div class="mt-4">
                        <a :href="$store.state.redirectUrl" class="bg-yellow rounded-full p-2 px-16 font-semibold shadow-xl uppercase inline-block">{{ $t("Vai all'offerta") }}</a>
                    </div>
                    <div class="mb-4">
                        <button @click="closeRedirectLanding" class="p-2 px-16 font-semibold underline text-sm cursor-pointer" type="button">{{ $t("Rimani su questa pagina") }}</button>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="modal">
            <div v-if="showModal" class="bg-black bg-opacity-25 absolute inset-0 flex items-center justify-center z-10">
                <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-3xl mb-64 max-w-xl text-gray-700 shadow-xl">
                    <img class="block mx-auto sm:inline-block align-middle my-3" src="@/assets/images/alert-clock.svg" />
                    <h1 class="text-2xl sm:text-3xl font-semibold md:w-2/3 mx-auto">
                        {{ $t("Guarda che stai perdendo una grossa opportunità") }}
                    </h1>
                    <p class="my-6">{{ $t("Ti manca poco per terminare!") }}</p>
                    <div class="mb-4">
                        <button @click="closeModal" class="bg-accent rounded-full p-2 px-16 font-semibold shadow-xl" type="button">{{ $t("OK") }}</button>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="error">
            <div v-if="showError" class="bg-black bg-opacity-25 absolute inset-0 flex items-center justify-center z-10">
                <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-3xl mb-64 max-w-xl text-gray-700 shadow-xl">
                    <h1 class="text-2xl sm:text-3xl font-semibold md:w-2/3 mx-auto">
                        {{ $t("Siamo spiacenti") }}
                    </h1>
                    <p class="my-6">{{ $t("Abbiamo rilevato un errore nella connessione con il server, per favore ricarica la pagina") }}</p>
                    <div class="mb-4">
                        <button @click="reload" class="bg-accent rounded-full p-2 px-16 font-semibold shadow-xl" type="button">{{ $t("OK") }}</button>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="error">
            <div v-if="pendingOrder" class="bg-black bg-opacity-50 absolute inset-0 flex items-start justify-center z-10">
                <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-lg mt-8 max-w-xl text-gray-700">
                    <h1 class="text-2xl font-bold">
                        {{ $t("Impossibile procedere") }}
                    </h1>
                    <p class="my-6">{{ $t("Hai già inviato una richiesta di prestito: controlla la tua e-mail per conoscere l’esito.") }}<br>{{ $t("Se invece vuoi compilare una nuova richiesta, clicca il bottone qui sotto.") }}</p>
                    <div>
                        <button @click="reload" class="btn btn-primary" type="button">{{ $t("Nuova richiesta") }}</button>
                    </div>
                </div>
            </div>
        </transition>

        <Footer v-if="showFooter" />

        <WaWidget v-if="showWaWidget" />

    </div>
</template>
<script>
// @ is an alias to /src
import Progress from "@/components/Progress.vue";
import PromoModal from "@/components/PromoModal.vue";
import SecureFormBadge from "@/components/SecureFormBadge.vue";
import { mapGetters } from "vuex"

import $ from "jquery";
import WaWidget from "./components/WaWidget.vue";

export default {
    components: {
        Progress,
        PromoModal,
        SecureFormBadge,
        WaWidget
    },
    data() {
        return {
            showModal: false,
            logoSrc: this.$store.state.logo
        };
    },
    mounted() {
        $("#content")
            .stop()
            .animate({ opacity: 1 }, 500, "swing", function() {});

        window.addEventListener("mousemove", this.showModalHandler);

        this.$ga.pageview()
    },
    destroyed() {
        window.removeEventListener("mousemove", this.showModalHandler);
    },
    computed: {
        ...mapGetters(["showWaWidget"]),
        percentage() {
            if (this.$route.meta && this.$route.meta.percentage) {
                return parseInt(this.$route.meta.percentage)
            }
            return 0
        },
        flashMessage() {
            return this.$store.state.flashMessage
        },
        showError() {
            return this.$store.state.userData.hasError
        },
        showRedirectLanding() {
            return this.$store.state.redirect
        },
        pendingOrder() {
            return this.$store.state.userData.pendingOrder
        },
        showFooter() {
            return ! this.$route.meta.footerHidden
        },
        showSecureFormBadge() {
            return (
                ! ["Homepage"].includes(this.$route.name)
            );
        },
        showFlashMessage() {
            return (
                ["Rata", "RataPowerCredit"].includes(this.$route.name)
            );
        },
        showBackButton() {
            return ! this.$route.meta.backHidden
        },
        contentClass() {
            let route = this.$route.name ? this.$route.name.toLowerCase() : ''
            let className = `theme-${this.$store.state.theme} ${route}`
            if(this.showModal || this.showError || this.pendingOrder) className += ' overflow-hidden h-screen'
            return className
        },
        containerClass() {
            const route = this.$route.name ? this.$route.name.toLowerCase() : ''
            const containerFull = this.$route.meta.containerFull

            if(route != 'homepage' && route != 'privacypolicy' && !containerFull)
                return 'w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-4 mb-10 lg:my-10'
            else
                return ''
        },
        progressColors() {
            let colors = {};
            if (this.$store.state.envType == 'banche') {
                colors.bg = 'green-1'
                colors.text = 'white'
            } else {
                colors.bg = 'yellow'
                colors.text = 'black'
            }
            return colors;
        }
    },
    methods: {
        showModalHandler(event) {
            this.$nextTick(() => {
                if (this.$route.meta.alert && process.env.NODE_ENV != "development") {
                    if (event.pageY < 10) {
                        this.showModal = true;
                    }
                }
            });
        },
        closeModal() {
            this.showModal = false;
            window.focus();
        },
        closeRedirectLanding() {
            this.$store.state.redirect = false
            window.clearTimeout(this.$store.state.redirectTimeout)
        },
        reload() {
            this.showError = false;
            this.$store.commit("resetState");
            if(this.$store.state.protected)
                this.$router.push({ name: "Login" })
            else
                this.$router.push({ name: "Homepage" })
        }
    }
};
</script>
