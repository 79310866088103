<template>
    <section class="metlife pb-8 md:pb-5 pt-6">
        <div class="container text-center relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" />
        </div>
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-6 mb-10 pb-2">
            <Precompiled />
        </div>
    </section>
</template>
<script>
import Precompiled from "@/views/forms/MetlifePrecompiled";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";

export default {
    components: { Steps, Precompiled },
    computed: {
        ...mapUserData([
            "document_expired"
        ]),
        steps() {
            return this.document_expired ? 4 : 3
        }
    }
};
</script>